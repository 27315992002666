$accent-color: #e61f57;

* {
    box-sizing: border-box;
}

.chainName {
    position: absolute;
    text-align: right;
    top: 2%;
    right: 5%;
}

body {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Comfortaa;
    padding: 0% 2%;
}

.wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.PerkCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}

.card {
    top: 0%;
    left: 0%;
    width: 410px;
    height: 500px;
    margin: 0em;
    perspective: 1500px;
}

.content {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 99%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.75, 2.05, 0.85, 1);
}

.more {
    display: none;
}

    .more:checked ~ .content {
        transform: rotateY(180deg);
    }

.front,
.back {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    border: 2px solid rgb(240, 240, 240);
    border-radius: 6px;
}

    .front .inner,
    .back .inner {
        height: 100%;
        display: grid;
        padding: 1.5em;
        transform: translateZ(80px) scale(0.94);
    }

.front {
    background-color: white;
}

    .front:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 6px;
        backface-visibility: hidden;
        background: linear-gradient(rgba(255, 242, 166, 0.1), rgba(0, 0, 0, 0.2));
    }

    .front .inner {
        grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
        justify-items: center;
    }

    .front h2 {
        margin: auto;
        padding-top: 110%;
        grid-row: 2;
        text-transform: uppercase;
        letter-spacing: 5px;
        color: #fff;
        font-weight: 500;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
    }

.rating {
    grid-row: 3;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    display: flex;
    flex-flow: row nowrap;
}

    .rating i {
        margin: 0 1px;
    }

#gen-payButtons {
    position: absolute;
    top: 25%;
    left: 5%;
}

.back {
    background-color: black;
    color: white;
    transform: rotateY(180deg);
    border: 3px solid rgb(240, 240, 240);
}

    .back .inner {
        grid-template-rows: 12fr 1fr 1fr 2fr 50fr 1fr 1fr;
        grid-template-columns: repeat(3, auto);
        grid-column-gap: 1.1em;
        justify-items: center;
    }

.tokens {
    border: 2px solid;
    padding: 3px;
    background-color: #e61f57;
    cursor: default;
    position: relative;
    display: flex;
    align-items: center;
    grid-row: 2;
}

    .tokens:not(:first-of-type):before {
        content: '';
        position: absolute;
        left: -0.8em;
        height: 0px;
        width: 0px;
        background-color: #ccc;
    }

    .tokens:hover,
    .tokens:active {
        background-color: lightblue;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
        text-shadow: none;
        color: white;
    }

    .tokens span {
        font-size: 1.8em;
        font-weight: 700;
        padding-left: 5px;
        padding-right: 5px;
        width: 85px;
    }

    .tokens i:before {
        background: linear-gradient(40deg, $accent-color, rgb(67, 138, 243));
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    }

    .tokens i {
        font-size: 1.2em;
    }

.token-symbols {
    margin-left: 0em;
}

    .token-symbols span {
        display: block;
        margin-top: 0.3em;
        font-size: 100%;
        font-weight: 600;
    }

input[type="text"] {
    border: 2px solid black;
    font-size: 65%;
    margin-bottom: 10px;
    text-align: center;
    width: 295%;
}

::placeholder {
    font-size: 125%;
    text-align: center;
}

.description {
    grid-row: 5;
    grid-column: 1/-1;
    font-size: 1.0em;
    border-radius: 5px;
    font-weight: 100;
    line-height: 1.2em;
    overflow-wrap: anywhere;
    overflow: auto;
    white-space: pre-line;
    width: 99%;
}

.title {
    font-weight: 600;
    grid-row: 1;
    font-size: 1.3em;
    word-wrap: break-word;
    grid-column: 1/-1;
    justify-self: center;
    white-space: pre-wrap;
    margin-top: -10px;
    margin-bottom: 5px;
}

    .title input[type="text"] {
        max-width: 60%; /* Maximum width */
        min-width: 60%; /* Minimum width */
    }

.button {
    grid-row: -1;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 900;
    cursor: pointer;
    display: block;
    padding: 0 1.5em;
    height: 3em;
    line-height: 2.9em;
    min-width: 6em;
    background-color: #e61f57;
    border: solid 2px #fff;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    left: 50%;
    backface-visibility: hidden;
    transition: 0.3s ease-in-out;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

    .button:hover,
    .button:active {
        background-color: lightblue;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
        text-shadow: none;
        color: white;
    }

    .button.return {
        grid-column: 1/-1;
        line-height: 3em;
        min-width: 6em;
        backface-visibility: hidden;
        border: solid 2px #fff;
        transition: 0.3s ease-in-out;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    }

        .button.return:hover,
        .button.return:active {
            background-color: lightblue;
            box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
            text-shadow: none;
            color: white;
        }

::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

::-webkit-scrollbar-track {
    background: #e61f57;
}

::-webkit-scrollbar-thumb {
    background: lighten($accent-color, 20%);
}

    ::-webkit-scrollbar-thumb:hover {
        background: $accent-color;
    }

@media only screen and (max-width: 767px) {
    body {
        padding: -10% 2%;
    }

    .front,
    .back {
        top: -3%;
    }

    #gen-payButtons {
        position: absolute;
        top: 20%;
        left: 0%;
        padding: 5%;
    }
}
