.disclaimer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10002; /* Higher than other overlays */
}

.disclaimer-modal {
    position: relative;
    top: 0;
    left: 1%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    font-family: Comfortaa, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

    .disclaimer-modal h2 {
        margin: 0 0 15px;
        font-size: 24px;
        color: #e61f57;
    }

    .disclaimer-modal p {
        margin: 10px 0;
        font-size: 16px;
        color: #333;
    }

.disclaimer-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.disclaimer-agree, .disclaimer-cancel {
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-family: Comfortaa, sans-serif;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.disclaimer-agree {
    background-color: #e61f57;
    color: white;
}

    .disclaimer-agree:hover {
        background-color: rgba(0, 100, 255, 0.9);
    }

.disclaimer-cancel {
    background-color: #999;
    color: white;
}

    .disclaimer-cancel:hover {
        background-color: #666;
    }

/* Dark mode styles */
body.DarkMode .disclaimer-modal {
}

    body.DarkMode .disclaimer-modal p {
        color: #333;
    }

body.DarkMode .disclaimer-cancel {
    background-color: #666;
}

    body.DarkMode .disclaimer-cancel:hover {
        background-color: #555;
    }

/* Mobile adjustments */
@media only screen and (max-width: 767px) {
    .disclaimer-modal {
        width: 85%;
        padding: 15px;
    }

        .disclaimer-modal h2 {
            font-size: 20px;
        }

        .disclaimer-modal p {
            font-size: 14px;
        }

    .disclaimer-agree, .disclaimer-cancel {
        font-size: 14px;
        padding: 8px 15px;
    }
}
