@import url('https://fonts.googleapis.com/css2?family=Comfortaa');

/* Ensure full page coverage for all modes */

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.App {
    font-family: Comfortaa;
    text-align: center;
}

/* Links */
#a:link, #a:visited, #a:hover, #a:active {
    color: #e61f57;
    cursor: default;
    background-color: transparent;
    text-decoration: none;
}

.transaction-link:link, .transaction-link:visited, .transaction-link:hover, .transaction-link:active {
    color: #e61f57;
    cursor: default;
    background-color: transparent;
    text-decoration: none;
    display: block;
}

/* Default styles for light mode (WhiteMode) */
body.WhiteMode {
    background-color: white !important;
    min-width: 100%; /* At least viewport height */
    width: 100%; /* Grow with content */
    min-height: 200%; /* At least viewport height */
    height: auto; /* Grow with content */
}

/* Dark mode */
body.DarkMode {
    background-color: black !important;
    min-width: 100%; /* At least viewport height */
    width: 100%; /* Grow with content */
    min-height: 100%; /* At least viewport height */
    height: auto; /* Grow with content */
}

/* Conditional positioning for PerkCard */
body.WhiteMode .PerkCard {
    zoom: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Added to center vertically within flex */
    min-width: 100%; /* At least viewport height */
    width: auto; /* Grow with content */
    min-height: 100%; /* At least viewport height */
    height: auto; /* Grow with content */
    position: absolute; /* Added to allow centering */
    top: 45%; /* Center vertically */
    left: 49%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for element size */
    text-align: center;
}

body.DarkMode .PerkCard {
    zoom: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Added to center vertically within flex */
    min-width: 100%; /* At least viewport height */
    width: auto; /* Grow with content */
    min-height: 100%; /* At least viewport height */
    height: auto; /* Grow with content */
    position: absolute; /* Added to allow centering */
    top: 45%; /* Center vertically */
    left: 49%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for element size */
    text-align: center;
}

.btn-on {
    border: 3px solid;
    border-radius: 6px;
    cursor: pointer;
    font-size: 25px;
    padding: 5px 7px;
    pointer-events: auto;
}

#start {
    border: 3px solid;
    border-radius: 6px;
    position: absolute;
    left: 50%;
    transform: translateX(200%);
    font-family: Comfortaa;
    font-size: 25px;
    font-weight: normal;
    height: 25px;
    text-align: center;
    padding: 8px 7px;
    width: 108px;
}

.form-action-button {
    background: #e61f57;
    border: 3px solid; /* Defaults to black in WhiteMode */
    border-radius: 6px;
    font-family: Comfortaa;
    font-size: 20px;
    font-weight: bold;
    height: 35px;
    justify-content: center;
    padding: 5px 0 10px;
    width: 85%;
    margin: auto;
    display: block;
}

    .form-action-button:hover {
        background: rgba(0, 100, 255, 0.9);
    }

/* Add cursor to payButtons */
.payButtons {
    background: #e61f57;
    border: 3px solid;
    border-radius: 6px;
    display: inline-block;
    font: inherit;
    height: 20px;
    justify-content: center;
    line-height: 22px;
    padding: 5px 0 4px;
    width: 45%;
    cursor: pointer; /* Added */
}

.menu-button {
    border-radius: 6px;
    background: none;
    border: none;
    color: #e61f57;
    cursor: pointer;
    font-family: Comfortaa;
    font-size: 20px;
    padding: 0 5px;
    text-decoration: none;
}

.claim-action-button {
    background: #e61f57;
    border: 3px solid; /* Defaults to black in WhiteMode */
    border-radius: 6px;
    font-family: Comfortaa;
    font-size: 20px;
    font-weight: bold;
    height: 35px;
    justify-content: center;
    padding: 5px 0 10px;
    width: 30%;
    margin: auto;
    display: block;
}

    .claim-action-button:hover {
        background: rgba(0, 100, 255, 0.9);
    }

.claim-toggle-button {
    background: #e61f57;
    border: 3px solid;
    border-radius: 6px;
    float: right;
    font-family: Comfortaa;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    margin: auto;
    padding: 5px 0 10px;
    width: 30%;
}

    .claim-toggle-button:hover {
        background: rgba(0, 100, 255, 0.9);
    }

/* Dark mode overrides with higher specificity */
body.DarkMode #start {
    border: 3px solid white !important;
}

body.DarkMode .form-action-button,
body.DarkMode .claim-toggle-button,
body.DarkMode .claim-action-button,
body.DarkMode .payButtons {
    border: 3px solid white;
    background: #e61f57 !important;
    color: white;
}

    body.DarkMode .form-action-button:hover,
    body.DarkMode .claim-toggle-button:hover,
    body.DarkMode .claim-action-button:hover,
    body.DarkMode .payButtons:hover {
        background: rgba(0, 100, 255, 0.9) !important;
    }

body.DarkMode .menu-button {
    color: #e61f57;
}

body.DarkMode input[type="text"],
body.DarkMode textarea[type="text"] {
    border: 2px solid white;
}

    body.DarkMode input[type="text"]:hover,
    body.DarkMode textarea[type="text"]:hover {
        border: 2px solid #e61f57;
    }

/* Only show gradient when .has-scroll is present */
.react-select__menu.has-scroll::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
}

body.DarkMode .react-select__menu.has-scroll::after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

body.DarkMode .react-select {
    border: 2px solid white;
}

body.DarkMode .react-select__single-value {
    color: black; /* Ensure visibility on white input background */
}

body.DarkMode .react-select__option {
    color: black;
}

body.DarkMode .react-select:hover {
    border: 2px solid #e61f57;
}

/* Other elements */
#darkMode {
    top: 87%;
}

#darkMode, #helpMode {
    left: 3%;
    margin: 0;
    position: fixed;
    width: 80px;
}

#approval-objects {
    color: white;
    padding: 180px;
}

.DYKmessage {
    font-size: 18px;
    white-space: pre-line;
}

#errorMessage {
    bottom: 1%;
    left: 3%; /* Center horizontally */
    width: 54%;
    margin: auto;
    position: fixed;
    text-align: left;
    font-size: 16px;
}

#flare-f {
    color: #e61f57;
    font-size: 100px;
    font-weight: bold;
}

#form-success {
    align-items: center;
    cursor: default;
    font-family: Comfortaa;
    justify-content: right;
    left: 52%;
    margin: 0;
    overflow: visible;
    position: absolute;
    top: 65%;
    width: 40%;
}

#generator, #mixer, #p2p-trade {
    cursor: default;
    left: 55%;
    position: absolute;
    top: 60%;
    width: 25%;
    text-align: center;
    padding-bottom: 400px;
    overflow: visible;
    pointer-events: none; /* Default to no interaction */
}

    /* When shown, enable pointer events and set appropriate z-index */
    #generator.show {
        z-index: 3;
        pointer-events: auto;
    }

    #mixer.show {
        z-index: 2;
        pointer-events: auto;
    }

    #p2p-trade.show {
        z-index: 1;
        pointer-events: auto;
    }

    /* When hidden, ensure no interference */
    #generator.hide, #mixer.hide, #p2p-trade.hide {
        z-index: -1; /* Push hidden elements below everything */
        pointer-events: none; /* Disable interaction */
    }

#goBackbutton {
    font-weight: bold;
    font-size: 15px;
    line-height: 27px;
    height: 20px;
    padding-bottom: 9px;
    padding-top: 4px;
    width: 65%;
}

#helpMode {
    top: 80%;
    z-index: 3000;
}

#menu {
    color: #e61f57;
    font-size: 20px;
    margin: auto;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

#tokenPageMessages {
    margin: auto;
    text-align: center;
    width: 50%;
    position: absolute;
    left: 51%;
    transform: translateX(-50%);
    top: 55%; /* Lowered from 45% */
}

    #tokenPageMessages > div {
        margin-bottom: 5px; /* Reduced spacing between message and transaction hash */
    }

#welcomeMessage {
    align-items: center;
    cursor: default;
    display: block;
    font-size: 20px;
    height: 23px;
    justify-content: center;
    left: 30%;
    margin: auto;
    top: 160px;
}

#yourPERKs {
    position: relative;
    top: 0;
    width: 100%;
    text-align: center;
}

.approveMessage {
    font-size: 21px;
    white-space: pre-line;
}

/* Adjust overlay styles */
.back-Mainoverlay {
    background-color: rgba(0, 100, 255, 0.9);
    display: block;
    margin: 0; /* Remove margin to ensure full coverage */
    position: fixed;
    left: 0;
    top: -7%;
    min-width: 100%; /* At least viewport height */
    width: auto; /* Grow with content */
    min-height: 120%; /* At least viewport height */
    height: auto; /* Grow with content */
    opacity: 0;
    overflow: hidden;
    z-index: 10000;
}

    .back-Mainoverlay.fade-in {
        animation: fadeIn 0.5s ease-in forwards;
    }

    .back-Mainoverlay.fade-out {
        animation: fadeOut 0.5s ease-out forwards;
    }

/* Specific fix for PerkCard on desktop */
.PerkCard .back-Mainoverlay {
    min-width: 101%; /* At least viewport height */
    width: auto; /* Grow with content */
    min-height: 120%; /* At least viewport height */
    height: auto; /* Grow with content */
}

.factory-card-container {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: 10001;
    padding: 15px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fade-animation {
    transition: opacity 0.4s ease-out;
}

    .fade-animation.show {
        opacity: 1;
    }

    .fade-animation.hide {
        opacity: 0;
    }

.font-title {
    cursor: default;
    display: flex;
    font-family: Comfortaa;
    font-size: 95px;
    font-weight: bold;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
    position: relative;
    top: 4%;
}

input[type="submit"] {
    background: #e61f57;
    border: 3px solid black;
    border-radius: 6px;
    cursor: pointer;
    font: inherit;
    height: 35px;
    justify-content: center;
    padding: 5px 0 10px;
    width: 96%;
}

    input[type="submit"]:hover, input[type="submit"]:active {
        background: rgba(0, 100, 255, 0.9);
    }

input[type="text"] {
    background-color: white;
    border: 2px solid black;
    border-radius: 6px;
    color: black !important;
    font-family: Comfortaa;
    margin: 3% auto;
    padding: 10px;
    text-align: center;
    max-width: 85%; /* Adjusted to match react-select */
    min-width: 85%;
    box-sizing: border-box;
    display: block;
    pointer-events: auto;
}

    input[type="text"]:hover {
        border: 2px solid #e61f57;
    }

textarea[type="text"] {
    background-color: white;
    border: 2px solid black;
    border-radius: 6px;
    font-family: Comfortaa;
    margin: 3% auto;
    padding: 10px;
    text-align: center;
    height: 12%;
    max-width: 85%; /* Adjusted to match react-select */
    min-width: 85%;
    box-sizing: border-box;
    display: block;
    pointer-events: auto;
}

    textarea[type="text"]:hover {
        border: 2px solid #e61f57;
    }

.menu-button:hover, .menu-button:active {
    text-decoration: underline;
}

.menu-link {
    color: #e61f57;
    cursor: pointer;
    font-size: 20px;
    text-decoration: none;
    padding: 0 5px;
}

    .menu-link:hover, .menu-link:active {
        text-decoration: underline;
    }

/* Adjust overlay-close positioning */
.overlay-close {
    position: absolute;
    top: -320px; /* Position just above the card (adjust as needed) */
    right: -280px; /* Align with the right edge of the card, accounting for padding */
    color: #999;
    font-size: 40px;
    cursor: pointer;
    transition: color 0.2s;
}

    .overlay-close:hover {
        color: #333;
    }

.payButtons:hover, .payButtons:active {
    background: rgba(0, 100, 255, 0.9);
}

.react-select {
    margin: 3% auto;
    width: 85%;
    border: 2px solid black;
    border-radius: 6px;
    box-sizing: border-box;
    color: black;
}

    .react-select:hover {
        border: 2px solid #e61f57;
    }

.react-select__menu {
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
}

.react-select__option {
    color: black;
}

.react-select__single-value {
    color: black;
}

.slide-animation {
    transition: max-height 0.4s ease-out, opacity 0.4s ease-out;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
}

    .slide-animation.show {
        max-height: 500px;
        opacity: 1;
    }

    .slide-animation.hide {
        max-height: 0;
        opacity: 0;
    }

.slide-up-menu {
    transition: transform 0.4s ease-out, opacity 0.4s ease-out;
}

    .slide-up-menu.show {
        transform: translateY(0);
        opacity: 1;
    }

    .slide-up-menu.hide {
        transform: translateY(100px);
        opacity: 0;
    }

.start-block {
    justify-content: center;
    position: absolute;
    left: 1%;
    top: 20%;
    white-space: nowrap;
    width: 100%;
    height: 300px;
    overflow: visible;
}

/* Mobile adjustments */
@media only screen and (max-width: 767px) {
    .App {
        height: auto; /* Grow with content */
    }

    .back-Mainoverlay {
        background-color: rgba(0, 100, 255, 0.9);
        display: block;
        margin: 0; /* Remove margin to ensure full coverage */
        position: fixed;
        left: 0;
        top: 0;
        min-width: 100%; /* At least viewport height */
        width: auto; /* Grow with content */
        min-height: 100%; /* At least viewport height */
        height: auto; /* Grow with content */
        opacity: 0;
        overflow: hidden;
        z-index: 10000;
    }

    body {
        zoom: 0.6;
        min-width: 100%; /* At least viewport height */
        width: 100%; /* Grow with content */
        min-height: 100%; /* At least viewport height */
        height: auto; /* Grow with content */
    }

        body.WhiteMode {
            background-color: white !important;
            min-width: 100%; /* At least viewport height */
            width: 100%; /* Grow with content */
            min-height: 150%; /* At least viewport height */
            height: auto; /* Grow with content */
        }

        body.DarkMode {
            background-color: black !important;
            min-width: 100%; /* At least viewport height */
            width: 100%; /* Grow with content */
            min-height: 100%; /* At least viewport height */
            height: auto; /* Grow with content */
        }

        body.WhiteMode .PerkCard {
            zoom: 1.1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; /* Added to center vertically within flex */
            position: absolute; /* Added to allow centering */
            top: 45%; /* Center vertically */
            left: 50%; /* Center horizontally */
            transform: translate(-50%, -50%); /* Adjust for element size */
            min-width: 100%; /* At least viewport height */
            width: auto; /* Grow with content */
            min-height: 110%; /* At least viewport height */
            height: auto; /* Grow with content */
            padding-top: 0; /* Reset padding */
            padding-bottom: 0; /* Reset padding */
        }

        body.DarkMode .PerkCard {
            zoom: 1.1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; /* Added to center vertically within flex */
            position: absolute; /* Added to allow centering */
            top: 45%; /* Center vertically */
            left: 50%; /* Center horizontally */
            transform: translate(-50%, -50%); /* Adjust for element size */
            min-width: 100%; /* At least viewport height */
            width: auto; /* Grow with content */
            min-height: 110%; /* At least viewport height */
            height: auto; /* Grow with content */
            padding-top: 0; /* Reset padding */
            padding-bottom: 0; /* Reset padding */
        }

    .btn-on {
        font-size: 35px;
        padding: 10px 4px;
    }

    .factory-card-container {
        width: 90%;
        padding: 10px;
    }

    .font-title {
        width: 98%;
        margin: auto;
    }

    #form-success {
        left: 55%;
        top: 50%;
        width: 40%;
    }

    #goBackbutton {
        font-weight: bold;
        font-size: 15px;
        line-height: 27px;
        height: 30px;
        padding: 8px 1px 4px;
        width: 80%;
    }

    #generator, #mixer, #p2p-trade {
        cursor: default;
        left: 55%;
        position: absolute;
        top: 45%;
        width: 42%;
        pointer-events: auto;
        text-align: center;
        padding-bottom: 150px;
        overflow: visible;
    }

    #helpMode {
        top: 82%;
    }

    #menu {
        font-size: 20px;
    }

    .menu-button {
        font-size: 20px;
    }

    .menu-link {
        font-size: 20px;
    }

    #approval-objects {
        padding: 240px 140px 180px;
    }

    .approveMessage {
        font-size: 23px;
    }

    #darkMode {
        top: 89%;
        font-size: 35px;
        padding: 10px 4px;
    }

    #darkMode, #helpMode {
        left: 3%;
        margin: 0;
        position: fixed;
        width: 145px;
    }

    .overlay-close {
        top: -400px; /* Slightly more space above on mobile for better visibility */
        right: 80px; /* Adjust for smaller card size on mobile */
        font-size: 50px;
    }

    .payButtons {
        font-weight: bold;
        font-size: 15px;
        line-height: 27px;
        height: 30px;
        padding: 8px 1px 4px;
        width: 100px;
    }

    .react-select {
        margin: 12px auto;
        width: 85%;
        border: 2px solid black;
    }

        .react-select:hover {
            border: 2px solid #e61f57;
        }

    .react-select__menu {
        width: 85%;
    }

    #start {
        position: absolute;
        left: 62%;
        transform: translateX(50%);
        border: 3px solid;
        font-size: 35px;
        padding: 8px 8px 25px;
        width: 150px;
    }

    #tokenPageMessages {
        width: 80%;
        top: 50%; /* Lowered from 40% */
    }

        #tokenPageMessages > div {
            margin-bottom: 5px; /* Reduced spacing */
        }

    .slide-animation.show {
        max-height: 500px;
    }

    .start-block {
        top: 18%;
        z-index: 500;
    }

    input[type="submit"] {
        font-weight: bold;
    }

    input[type="text"] {
        border: 2px solid black;
        margin: 15px auto;
    }

    textarea[type="text"] {
        border: 2px solid black;
        margin: 15px auto;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
